<template>
	<div class="mt-5">
		<v-row class="mx-auto col-lg-10 col-md-10 col-sm-10 col-xs-10">
			<v-col>
				<v-card
					color="grey lighten-3"
					class="d-flex align-center"
					height="50"
					width="150"
					flat
					@click="toPrev"
					v-if="!isFirst"
				>
					<div
						class="flex-grow-1 text-center"
					>
						前へ戻る
					</div>
				</v-card>
			</v-col>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-col>
				<v-card
					color="grey lighten-3"
					class="d-flex align-center"
					height="50"
					width="150"
					flat
					@click="toNext"
				>
					<div
						class="flex-grow-1 text-center"
					>
						<span v-if="!isLast">次へ進む</span>
						<span v-else>回答を送信する</span>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'NextPrevButton',
	props: {
		questions: Number
	},
	computed: {
		q() {
			if (this.$route.params.q == undefined) {
				return 1
			} else {
				return Number(this.$route.params.q)
			}
		},
		test() {
			return this.$route.params.id
		},
		isFirst() {
			if (this.q == 1 || this.q == undefined) {
				return true
			} else {
				return false
			}
		},
		isLast() {
			if (this.q == this.questions) {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		toNext() {
			let link = '';
			let nq = this.q + 1;
			if (this.q < this.questions) {
				link = `/test/${this.test}/q/${nq}`;
			} else {
				link = `/test/${this.test}/result`;
			}
			this.$router.push({ path: link });
		},
		toPrev() {
			let link = '';
			let pq = this.q - 1;
			if (this.q != undefined && this.q != 1) {
				link = `/test/${this.test}/q/${pq}`;
			}
			this.$router.push({ path: link });
		}
	}
}
</script>