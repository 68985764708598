<template>
	<div>
		<div v-if="noTests">
			問題が存在しません。<br/>
			時間をおいてもう一度お試しください。
		</div>
		<v-container>
			<v-row>
				<v-col>
					<v-card
						class="mx-auto"
						max-width="400"
						flat
					>
						<v-btn
							color="blue darken-2"
							dark
							x-large
							elevation="0"
							class="mx-auto col-10 mb-5"
							:key="'test-' + index"
							v-for="(test, index) in tests"
							:to="'/test/' + test.id"
						>
							{{ test.name }}
						</v-btn>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<div v-if="error">
			<p class="mt-2 mb-10">
				問題を取得できませんでした。<br/>
				時間をおいてもう一度お試しください。
			</p>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserTests',
	data () {
		return {
			error: false,
			noTests: false,
		}
	},
	async created () {
			this.setLoading(true)
			this.clearTestAnswer()
			await this.getPublishedTests().then(() => {
				this.setLoading(false)
				this.error = false
				if (!this.tests.length) {
					this.noTests = true
				} else {
					this.noTests = false
				}
			}).catch(err => {
				this.setLoading(false)
				this.error = true
				this.noTests = false
				console.error(err)
			})
	},
	computed: {
		...mapGetters({
				tests: 'statePublishedTests', loading: 'stateLoading'
		})
	},
	methods: {
			...mapActions(['getPublishedTests', 'setLoading', 'clearTestAnswer'])
	}
}
</script>