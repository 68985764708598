<template>
	<v-dialog
		v-model="infoDialog"
		width="800"
		scrollable
		persistent
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				v-bind="attrs"
				v-on="on"
				icon>
				<v-icon>mdi-help-circle-outline</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title class="pt-5 pb-5">
				<h2 class="text-h5">TermMatorについて</h2>
			</v-card-title>
			<v-card-text class="body-1">
				<p>
					TermMatorは、あなたの専門語彙量を推定するアプリケーションです。トップ画面から問題を選んで回答を始めてください。
				</p>
				<h6 class="text-h6">関連研究</h6>
				<p class="mt-2">
					TermMatorは、EBLISE (Evidence-based Library and Information Science Education) プロジェクトの一部として研究・開発・運営されています。関連研究は、<a href="https://eblise.org/publications/" target="_blank">こちら</a>をご覧ください。
				</p>
				<h6 class="text-h6">利用規約</h6>
				<p class="mt-2">
					TermMatorの機能を使用することで、この利用規約に同意したものとみなします。<br/><br/>
					TermMatorを使用したことによって得られた結果について、開発者は責任を負わないものとします。特にTermMatorは研究段階のアプリケーションで、不具合が生じることがありますので、ご注意ください。<br/><br/>
					TermMatorでは利用者の回答結果を収集します。これは専門語彙量推定アプリケーションとしての有用性を分析するためで、研究目的以外に利用することはありません。なお、TermMatorでは個人情報を収集しません。<br/><br/>
					TermMatorは大まかな語彙量を推定するものです。推定結果は絶対的な正解ではないことをご了承ください。
				</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="infoDialog = false"
				>
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'InfoDialog',
	data () {
		return {
			infoDialog: false
		}
	}
}
</script>

<style scoped>
h6 {
	color: #333;
}

a {
	text-decoration: none;
}
</style>