<template>
	<v-container>
		<v-row>
			<v-col class="mx-auto">
				<v-card
					class="mx-auto"
					max-width="400"
					tile
					flat
				>
					<p class="mt-5 mb-5 text-center">
						あなたの専門語彙量を推定します。<br/>
						問題を一つ選んで回答を始めてください。<br/>
						<span class="caption text--secondary">（複数の問題に回答した場合、全ての回答をもとに推定します）</span>
					</p>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="isExperimenting">
				<p>
					実験／調査実施中につき、問題を非公開にしています。<br/>
					後日あらためてお試しください。
				</p>
			</v-col>
			<v-col v-else>
				<UserSets/>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-divider></v-divider>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserLogin/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserSets from '@/components/UserSets'
import UserLogin from '@/components/UserLogin'

export default {
	name: 'UserHome',
	components: {
		UserSets,
		UserLogin
	},
	created () {
		this.clearSet()
		this.clearSets()
		this.clearTerms()
		this.clearPublishedSets()
		this.clearAnswers()
		this.clearAnswerScore()
		this.setNotAnswered(this.set.terms)
		this.clearScore()
		this.clearScores()
		this.clearSurveyAnswered()
		this.clearLibSurveyAnswered()
		this.clearMotSurveyAnswered()
		this.clearSurveyAnswers()
		this.clearLibSurveyAnswers()
		this.clearMotSurveyAnswers()
		this.clearUsers()
		this.clearMyUsers()
		this.clearUserUsers()
		this.clearPlainAdmins()
		this.clearGroupUsers()
		this.clearCurriculum()
		this.clearCurriculums()
		this.clearTests()
		this.clearPublishedTests()
		this.clearTermBanks()
		this.clearAnsweredScales()
		this.clearScaleItemAnswers()
		this.clearScaleAnswers()
		this.clearScaleItemNotAnswered()
		this.setLoading(false)
		this.setAlert(false)
	},
	computed: {
		...mapGetters({ set: 'stateSet' }),
		isExperimenting() {
			const isExperimenting = process.env.VUE_APP_IS_EXPERIMENTING
			if (isExperimenting === 'true') {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		...mapActions(['clearSets', 'clearSet', 'clearTerms', 'clearPublishedSets', 'setLoading', 'setAlert', 'clearAnswers', 'setNotAnswered', 'clearScore', 'clearScores', 'clearAnswerScore', 'clearSurveyAnswered', 'clearLibSurveyAnswered', 'clearUsers', 'clearMyUsers', 'clearUserUsers', 'clearPlainAdmins', 'clearSurveyAnswers', 'clearLibSurveyAnswers', 'clearMotSurveyAnswered', 'clearMotSurveyAnswers', 'clearGroupUsers', 'clearCurriculum', 'clearCurriculums', 'clearTests', 'clearPublishedTests', 'clearTermBanks', 'clearAnsweredScales', 'clearScaleItemAnswers', 'clearScaleAnswers', 'clearScaleItemNotAnswered'])
	}
}
</script>

<style scoped>
.v-divider {
	border-width: 1px;
}
</style>