import axios from 'axios'


const state = {
    scaleAnswers: [],
    scaleItemAnswers: [],
    scaleItemNotAnswered: [],
    answeredScales: []
}

const getters = {
    stateScaleAnswers: state => state.scaleAnswers,
    stateScaleItemAnswers: state => state.scaleItemAnswers,
    stateScaleItemNotAnswered: state => state.scaleItemNotAnswered,
    stateAnsweredScales: state => state.answeredScales
}

const actions = {
    addScaleItemAnswer({commit}, answer) {
        commit('addScaleItemAnswer', answer)
    },
    setScaleItemNotAnswered({commit}, items) {
        let itemIds = items.map((_, index) => index + 1)
        commit('setScaleItemNotAnswered', itemIds)
    },
    async postScaleItemAnswers({state}, payload) {
        let answers = []
        for (let answer of state.scaleItemAnswers) {
            let obj = {
                'answer': answer.answer,
                'scaleitem_id': answer.scaleItemId,
                'test_id': payload.testId,
                'user_id': payload.userId,
                'start_at': payload.startAt,
                'scalescore_id': payload.scaleScoreId
            }
            answers.push(obj)
        }
        await axios.post('/add_scaleanswers/', answers)
    },
    async getScaleAnswers({commit}) {
        let {data} = await axios.get('/scaleanswers/')
        commit('setScaleAnswers', data)
    },
    async downloadScaleAnswers() {
        return await axios.get('/scaleanswers/download/', { responseType: 'blob' })
    },
    clearScaleItemAnswers({commit}) {
        commit('setScaleItemAnswers', [])
    },
    clearAnsweredScales({commit}) {
        commit('setAnsweredScales', [])
    },
    clearScaleAnswers({commit}) {
        commit('setScaleAnswers', [])
    },
    clearScaleItemNotAnswered({commit}) {
        commit('setScaleItemNotAnswered', [])
    },
    setAnsweredScales({commit}, scales) {
        commit('setAnsweredScales', scales)
    },
    appendAnsweredScale({commit}, scale) {
        commit('appendAnsweredScale', scale)
    }
}

const mutations = {
    addScaleItemAnswer(state, answer) {
        let index = state.scaleItemAnswers.findIndex((obj => obj.scaleItemId === answer.scaleItemId))
        if (index === -1) {
            let notIndex = state.scaleItemNotAnswered.indexOf(answer.index)
            state.scaleItemAnswers.push(answer)
            state.scaleItemNotAnswered.splice(notIndex, 1)
        } else {
            if (answer.answer === undefined) {
                state.scaleItemAnswers.splice(index, 1)
                state.scaleItemNotAnswered.push(answer.index)
                state.scaleItemNotAnswered.sort((a, b) => a - b)
            } else {
                state.scaleItemAnswers[index].answer = answer.answer
            }
        }
    },
    setScaleItemAnswers(state, answers) {
        state.scaleItemAnswers = answers
    },
    setScaleItemNotAnswered(state, items) {
        state.scaleItemNotAnswered = items
    },
    setAnsweredScales(state, scales) {
        state.answeredScales = scales
    },
    appendAnsweredScale(state, scale) {
        state.answeredScales.push(scale)
    },
    setScaleAnswers(state, answers) {
        state.scaleAnswers = answers
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}