<template>
	<v-container class="mt-16">
		<v-row>
			<v-col>
				<AdminDashboardCard
					:title="`TermBanks`"
					:items="termbanks"
					:pageLink="`/admin/termbanks`"
				/>
			</v-col>
			<v-col>
				<AdminDashboardCard
					:title="`Terms`"
					:items="terms"
					:pageLink="`/admin/terms`"
				/>
			</v-col>
			<v-col>
				<AdminDashboardCard
					:title="`Sets`"
					:items="sets"
					:pageLink="`/admin/sets`"
				/>
			</v-col>
			<v-col>
				<AdminDashboardCard
					:title="`Tests`"
					:items="tests"
					:pageLink="`/admin/tests`"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<AdminDashboardCard
					:title="`UserGroups`"
					:items="userGroups"
					:pageLink="`/admin/usergroups`"
				/>
			</v-col>
			<v-col>
				<AdminDashboardCard
					:title="`Users`"
					:items="users"
					:pageLink="`/admin/users`"
				/>
			</v-col>
			<v-col>
				<AdminDashboardCard
					:title="`Score`"
					:items="scores"
					:pageLink="`/admin/score`"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<AdminDashboardCard
					:title="`Survey Answers`"
					:items="surveyanswers"
					:pageLink="`/admin/surveyanswers`"
				/>
			</v-col>
			<v-col>
				<AdminDashboardCard
					:title="`Lib Survey Answers`"
					:items="libsurveyanswers"
					:pageLink="`/admin/libsurveyanswers`"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminDashboardCard from '@/components/AdminDashboardCard'

export default {
	name: 'AdminDashboard',
	components: {
		AdminDashboardCard
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			termbanks: 'stateTermBanks',
			terms: 'stateTerms',
			sets: 'stateSets',
			tests: 'stateTests',
			users: 'stateUserUsers',
			userGroups: 'stateUserGroups',
			scores: 'stateScores',
			plainadmins: 'statePlainAdmins',
			surveyanswers: 'stateSurveyAnswers',
			libsurveyanswers: 'stateLibSurveyAnswers'
		})
	},
	async created () {
		this.setLoading(true)
		await this.update().then(() => {
			this.setLoading(false)
		}).catch(() => {
			this.setLoading(false)
		})
	},
	methods: {
		...mapActions(['getTermBanks', 'getTerms', 'getSets', 'getTests', 'getUserUsers', 'getPlainAdmins', 'getScores', 'getSurveyAnswers', 'getLibSurveyAnswers', 'setLoading', 'getUserGroups', 'getScaleAnswers', 'getMotSurveyAnswers', 'getUsers', 'getScales']),
		async update() {
			await this.getTermBanks()
			await this.getTerms()
			await this.getSets()
			await this.getTests()
			await this.getScales()
			await this.getUsers()
			await this.getUserUsers()
			await this.getPlainAdmins()
			await this.getUserGroups()
			await this.getScores()
			await this.getSurveyAnswers()
			await this.getLibSurveyAnswers()
			await this.getScaleAnswers()
			await this.getMotSurveyAnswers()
		}
	}
}
</script>
