<template>
	<div>
		<v-container v-if="noTest">
			<v-row>
				<v-col>
					<v-card
						class="mx-auto"
						max-width="500"
						tile
						flat
					>
						<p class="mt-5 mb-5 text-center">
							テストにアクセスできません。<br/>
							時間をおいてもう一度お試しください。
						</p>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-if="noSets || error">
				<v-col>
					<v-btn
						to="/experiments"
						large
						depressed
						class="mt-16"
						width="330"
					>
						戻る
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<v-container v-else>
			<v-row>
				<v-col>
					<div class="mt-5">
						<h1>
							司書課程の卒業生を対象としたアンケート調査
						</h1>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<UserSurveyDemo/>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserSurveyDemo from '@/components/UserSurveyDemo'

export default {
	name: 'UserTestSets',
	components: {
		UserSurveyDemo,
	},
	data () {
		return {
			error: false,
			sets: [],
			scales: [],
			noSets: false,
			noTest: true,
		}
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			loading: 'stateLoading',
			surveyAnswered: 'stateSurveyAnswered',
			libSurveyAnswered: 'stateLibSurveyAnswered',
			motSurveyAnswered: 'stateMotSurveyAnswered',
			alumniSurveyAnswered: 'stateAlumniSurveyAnswered',
			testSetAnswered: 'stateTestSetAnswered',
			scaleAnswered: 'stateAnsweredScales',
			testAnswer: 'stateTestAnswer',
			userId: 'stateUserId'
		}),
		testId() {
			return this.$route.params.id
		},
		isSurveyAnswered() {
			if (this.surveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else if (this.libSurveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else if (this.motSurveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else if (this.alumniSurveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else {
				return false
			}
		},
		isAllAnswered() {
			if (this.isSurveyAnswered && this.testSetAnswered.length === this.sets.length && this.scaleAnswered.length === this.scales.length) {
				return true
			} else {
				return false
			}
		},
		isExperimenting() {
			const isExperimenting = process.env.VUE_APP_IS_EXPERIMENTING
			if (isExperimenting === 'true') {
				return true
			} else {
				return false
			}
		}
	},
	async created () {
		this.setLoading(true)
		if (!this.testAnswer) {
			await this.createTestAnswer({
				test_id: this.testId,
				user_id: this.userId
			})
		}
		await this.getPublishedTest(this.testId).then(() => {
			this.setLoading(false)
			this.error = false
			this.sets = this.test.sets
			this.scales = this.test.scales
			if (!this.sets.length) {
				this.noSets = true
			} else {
				this.noSets = false
			}
			this.noTest = false
		}).catch(err => {
			this.error = true
			this.setLoading(false)
			this.noSets = false
			this.noTest = true
			console.error(err)
		})
	},
	methods: {
		...mapActions([
			'getPublishedTest',
			'setLoading',
			'createTestAnswer',
			'userLogout',
		]),
		isSetAnswered(id) {
			if (this.testSetAnswered.indexOf(id) >= 0) {
				return true
			} else {
				return false
			}
		},
		isScaleAnswered(id) {
			if (this.scaleAnswered.indexOf(id) >= 0) {
				return true
			} else {
				return false
			}
		},
		logout() {
			this.userLogout().then(() => {
				this.$router.push('/')
			})
		}
	}
}
</script>