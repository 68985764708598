import Vue from 'vue'
import VueRouter from 'vue-router'
import User from '@/views/User'
import UserHome from '@/components/UserHome'
import UserAnswer from '@/components/UserAnswer'
import UserExperiments from '@/components/UserExperiments'
import UserTestSets from '@/components/UserTestSets'
import UserSurvey from '@/components/UserSurvey'
import UserTestAnswer from '@/components/UserTestAnswer'
import UserScaleAnswer from '@/components/UserScaleAnswer'
import Admin from '@/views/Admin'
import AdminDashboard from '@/components/AdminDashboard'
import AdminTermBanks from '@/components/AdminTermBanks'
import AdminTerms from '@/components/AdminTerms'
import AdminSets from '@/components/AdminSets'
import AdminTests from '@/components/AdminTests'
import AdminScores from '@/components/AdminScores'
import AdminSurveyAnswers from '@/components/AdminSurveyAnswers'
import AdminLibSurveyAnswers from '@/components/AdminLibSurveyAnswers'
import AdminMotSurveyAnswers from '@/components/AdminMotSurveyAnswers'
import AdminAlumniSurveyAnswers from '@/components/AdminAlumniSurveyAnswers'
import AdminUsers from '@/components/AdminUsers'
import AdminUserGroups from '@/components/AdminUserGroups'
import AdminAdmins from '@/components/AdminAdmins'
import AdminCurriculums from '@/components/AdminCurriculums'
import AdminScales from '@/components/AdminScales'
import AdminCourses from '@/components/AdminCourses'
import AdminLogin from '@/components/AdminLogin'
import NotFound from '@/components/NotFound'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'User',
		component: User,
		children: [
			{
				path: '/',
				component: UserHome,
			},
			{
				path: '/set/:id',
				name: 'Answer',
				component: UserAnswer,
			},
			{
				path: '/experiments',
				name: 'Experiments',
				component: UserExperiments,
				meta: { requiresUser: true },
			},
			{
				path: '/test/:id',
				name: 'Test',
				component: UserTestSets,
				meta: { requiresUser: true },
				children: [
					{
						path: '',
						name: 'SurveyDemoHome',
						component: () => import('@/components/demo/Q1.vue')
					},
					{
						path: 'q/:q([1])',
						name: 'Q1',
						component: () => import('@/components/demo/Q1.vue')
					},
					{
						path: 'q/:q([2])',
						name: 'Q2',
						component: () => import('@/components/demo/Q2.vue')
					},
					{
						path: 'q/:q([3])',
						name: 'Q3',
						component: () => import('@/components/demo/Q3.vue')
					},
					{
						path: 'q/:q([4])',
						name: 'Q4',
						component: () => import('@/components/demo/Q4.vue')
					},
					{
						path: 'q/:q([5])',
						name: 'Q5',
						component: () => import('@/components/demo/Q5.vue')
					},
					{
						path: 'q/:q([6])',
						name: 'Q6',
						component: () => import('@/components/demo/Q6.vue')
					},
					{
						path: 'q/:q([7])',
						name: 'Q7',
						component: () => import('@/components/demo/Q7.vue')
					},
					{
						path: 'q/:q([8])',
						name: 'Q8',
						component: () => import('@/components/demo/Q8.vue')
					},
					{
						path: 'result',
						name: 'SurveyResult',
						component: () => import('@/components/demo/SurveyResult.vue')
					},
				]
			},
			{
				path: '/test/:id/survey',
				name: 'Survey',
				component: UserSurvey,
				meta: { requiresUser: true },
			},
			{
				path: '/test/:test_id/set/:set_id',
				name: 'TestAnswer',
				component: UserTestAnswer,
				meta: { requiresUser: true },
			},
			{
				path: '/test/:test_id/scale/:scale_id',
				name: 'ScaleAnswer',
				component: UserScaleAnswer,
				meta: { requiresUser: true },
			},
		]
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		children: [
			{
				path: '/admin/login',
				name: 'AdminLogin',
				component: AdminLogin,
			},
			{
				path: '/admin/dashboard',
				name: 'Dashboard',
				component: AdminDashboard,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/termbanks',
				name: 'TermBanks',
				component: AdminTermBanks,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/terms',
				name: 'Terms',
				component: AdminTerms,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/sets',
				name: 'Sets',
				component: AdminSets,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/tests',
				name: 'Tests',
				component: AdminTests,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/usergroups',
				name: 'UserGroups',
				component: AdminUserGroups,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/users',
				name: 'Users',
				component: AdminUsers,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/admins',
				name: 'Admins',
				component: AdminAdmins,
				meta: { requiresSuperAdmin: true }
			},
			{
				path: '/admin/score',
				name: 'Score',
				component: AdminScores,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/curriculums',
				name: 'Curriculums',
				component: AdminCurriculums,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/courses',
				name: 'Courses',
				component: AdminCourses,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/scales',
				name: 'Scales',
				component: AdminScales,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/surveyanswers',
				name: 'SurveyAnswers',
				component: AdminSurveyAnswers,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/libsurveyanswers',
				name: 'LibSurveyAnswers',
				component: AdminLibSurveyAnswers,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/motsurveyanswers',
				name: 'MotSurveyAnswers',
				component: AdminMotSurveyAnswers,
				meta: { requiresAdmin: true },
			},
			{
				path: '/admin/alumnisurveyanswers',
				name: 'AlumniSurveyAnswers',
				component: AdminAlumniSurveyAnswers,
				meta: { requiresAdmin: true },
			}
		]
	},
	{
		path: '*',
		name: 'NotFound',
		component: NotFound,
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresSuperAdmin)) {
		if (store.getters.isAdminAuthenticated) {
			store.dispatch('checkAdmin').catch(() => {
				store.dispatch('adminLogout')
				next({
					path: '/admin/login',
					query: { redirect: to.fullPath }
				})
			})
		}
		if (!store.getters.isAdminAuthenticated) {
			next({
				path: '/admin/login',
				query: { redirect: to.fullPath }
			})
		} else {
			next()
		}
	} else {
		next()
	}
	if (to.matched.some(record => record.meta.requiresUser)) {
		if (store.getters.isUserLoggedIn) {
			store.dispatch('checkUser').catch(() => {
				store.dispatch('userLogout')
				next({
					path: '/',
					query: { redirect: to.fullPath }
				})
			})
		}
		if (!store.getters.isUserLoggedIn) {
			next({
				path: '/',
				query: { redirect: to.fullPath }
			})
		} else {
			next()
		}
	} else {
		next()
	}
	if (to.matched.some(record => record.meta.requiresAdmin)) {
		if (store.getters.isAdminAuthenticated) {
			store.dispatch('checkAdmin').catch(() => {
				store.dispatch('adminLogout')
				next({
					path: '/admin/login',
					query: { redirect: to.fullPath }
				})
			})
		} else if (store.getters.isPlainAdminAuthenticated) {
			store.dispatch('checkPlainAdmin').catch(() => {
				store.dispatch('plainAdminLogout')
				next({
					path: '/admin/login',
					query: { redirect: to.fullPath }
				})
			})
		} else {
			next({
				path: '/admin/login',
				query: { redirect: to.fullPath }
			})
		}
	} else {
		next()
	}
})

export default router
