<template>
	<div class="mt-5 mb-5 text-center">
		<div v-if="isUserLoggedIn">
			<v-btn
				color="grey lighten-3"
				light
				large
				elevation="0"
				width="330"
				to="/experiments/"
			>
				実験／調査に参加する
			</v-btn>
		</div>
		<div v-else>
			<p>
				実験／調査協力者の方は、以下からログインしてください。<br/>
				<span class="caption text--secondary">（ブラウザの設定でCookieを有効にしてください）</span>
			</p>
			<v-form ref="userLoginForm">
				<v-container class="mx-auto col-10 col-xl-4 col-lg-6 col-md-6 col-sm-8 col-xs-12">
					<v-row>
						<v-col>
							<v-alert
									:value="alert"
									dense
									outlined
									text
									prominent
									type="error"
									transition="scale-transition"
								>
									ログインできませんでした。
								</v-alert>
						</v-col>
					</v-row>
					<v-row>
						<v-text-field
							label="ユーザー名"
							v-model="form.username"
							:rules="[rules.required]"
						></v-text-field>
					</v-row>
					<v-row>
						<v-text-field
							label="パスワード"
							v-model="form.password"
							:rules="[rules.required]"
							:type="show ? 'text' : 'password'"
							:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="show = !show"
						></v-text-field>
					</v-row>
					<v-row class="mt-10">
						<v-btn
							block
							large
							color="success"
							@click="login"
							depressed
							:loading="loading"
						>ログイン</v-btn>
					</v-row>
				</v-container>
			</v-form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserLogin',
	data () {
		return {
			show: false,
			alert: false,
			form: {
				username: '',
				password: '',
			},
			rules: {
					required: value => !!value || '必須'
			},
		}
	},
	computed: {
		...mapGetters({
			user: 'stateUser',
			isUserLoggedIn: 'isUserLoggedIn',
			loading: 'stateLoading'
		})
	},
	watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.alert = false, 2500)
			}
		}
	},
	methods: {
		...mapActions(['userLogin', 'setLoading']),
		async login() {
			if (this.$refs.userLoginForm.validate()) {
				this.setLoading(true)
				const User = new FormData()
				User.append('username', this.form.username)
				User.append('password', this.form.password)
				await this.userLogin(User).then(() => {
					this.setLoading(false)
					this.$router.push('/experiments/')
				}).catch(() => {
					this.setLoading(false)
					this.alert = true
				})
			}
		}
	}
}
</script>