<template>
	<div>
		<h2 class="dim" v-if="!isResult">Q{{q}}/Q{{questions}}</h2>
		<v-form ref="surveyForm">
			<v-container class="mx-auto col-lg-12 col-md-10 col-sm-10 col-xs-10">
				<router-view></router-view>
			</v-container>
		</v-form>
		<NextPrevButton :questions="questions" v-if="!isResult" />
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 1.2em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 1em;
}

.mot-survey-form {
	margin-top: 0.6em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

.dim {
	color: #999
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import NextPrevButton from '@/components/demo/NextPrevButton'


export default {
	name: 'UserSurvey',
	components: {
		NextPrevButton
	},
	data () {
		return {
			questions: 8,
			form: {
				motivation: null,
				certificate_year: null
			},
			rules: {
				required: value => !!value || '必須'
			},
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりであった", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりであった", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれなかった", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりなかった", value: 4 },
			],
			certificate_years: [
				{ text: "1970年代", value: 1 },
				{ text: "1980年代", value: 2 },
				{ text: "1990年代", value: 3 },
				{ text: "2000年代", value: 4 },
				{ text: "2010年代", value: 5 },
				{ text: "2020年代", value: 6 },
			]
		}
	},
	async created () {
		this.setLoading(true)
		this.clearScaleScore()
		const scaleId = this.test.scales[0].id
		await this.getScale(scaleId).then(() => {
			this.clearScaleItemAnswers()
			this.clearAnsweredScales()
			this.setScaleItemNotAnswered(this.scale.scaleitemorders)
			this.setLoading(false)
			this.scaleitemorders = this.scale.scaleitemorders
			this.startAt = Date.now()
		}).catch(err => {
			this.clearScale()
			this.error = true
			this.setLoading(false)
			console.error(err)
		})
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			scale: 'stateScale',
			scaleItemAnswered: 'stateScaleItemAnswered',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			answeredScales: 'stateAnsweredScales',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		},
		q() {
			if (this.$route.params.q == undefined) {
				return 1
			} else {
				return Number(this.$route.params.q)
			}
		},
		isResult() {
			if (this.$route.name == "SurveyResult") {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		}
	}
}
</script>